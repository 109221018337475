@import "../../../abstract/variables";
@import "../../../responsive/breakpoints";

.User_autopool_page_wrapper {
  margin-top: 10px;
  .User_autopool_dash_content {
    padding: 15px;
    min-height: 250px;
    .lna_section_title {
      text-align: start;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      h2 {
        font-size: 18px;
        color: $text-p-color;
        white-space: nowrap;
        @include breakpoints(mdlgMobile) {
          font-size: 14px;
        }
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 70%;
          background: $text-menu-active-color;
          top: 3px;
          left: -7px;
          bottom: 0;
        }
      }
    }
    .Autopool_dash_content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-row-gap: 20px;
      grid-column-gap: 20px;
      margin-top: 20px;
      @include breakpoints(4kdevice) {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 15px;
      }
      @include breakpoints(tablet) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 15px;
      }

      .LevelIncome_UserIncomeCard_dash_card_wrapper {
        position: relative;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        justify-content: space-between;
        padding: 30px 30px;
        background-color: $bg-white;
        transition: all 0.3s ease-in-out;
        box-shadow: $box-shadow-1;
        border-radius: 10px;
        @include breakpoints(laptop) {
          // height: 60px;
        }
        @include breakpoints(tablet) {
          height: auto;
        }
        .LevelIncome_UserIncomeCard_content {
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          transition: all 0.3s ease-in-out;
          justify-content: space-between;
          .LevelIncome_UserIncomeCard_widget_info {
            p {
              font-size: 20px;
              color: $text-p-color;
              // white-space: nowrap;
              // @include breakpoints(semiLargeLaptop) {
              //   font-size: 28px;
              // }
              // @include breakpoints(tablet) {
              //   font-size: 26px;
              // }
              // @include breakpoints(semiMiniTablet) {
              //   font-size: 24px;
              // }
              // @include breakpoints(largeMiniMobile) {
              //   font-size: 20px;
              // }
              // @include breakpoints(mobile) {
              //   font-size: 18px;
              // }
            }
            h2 {
              font-size: 16px;
              color: $text-p-color;
              margin-bottom: 10px !important;
              @include breakpoints(semiLargeLaptop) {
                // font-size: 16px;
              }
            }
            a {
              color: $text-black;
              font-size: 13px;
              text-transform: capitalize;
              font-weight: 500;
              &:hover {
                color: $text-menu-active-color;
              }
            }
          }
          .LevelIncome_UserIncomeCard_widget_icon {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            padding: 0px;
            margin-right: 0px;
            order: 2;
            .LevelIncomeImageIcon {
              width: 35px;
              padding: 15px;
            }
            svg {
              color: #fff;
              font-size: 30px;
              border-radius: 50px;
              padding: 10px;
            }
          }
        }
      }
    }
    .table_container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      justify-content: center;
      align-items: center;
      @include breakpoints(miniSemiTablet) {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 20px;
      }
      .table {
        .Autopool_dash_content {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
          grid-row-gap: 20px;
          grid-column-gap: 20px;
          margin-top: 20px;
          @include breakpoints(4kdevice) {
            grid-template-columns: repeat(4, 1fr);
            row-gap: 15px;
          }
          @include breakpoints(tablet) {
            grid-template-columns: repeat(1, 1fr);
            row-gap: 15px;
          }

          .LevelIncome_UserIncomeCard_dash_card_wrapper {
            position: relative;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            align-items: center;
            justify-content: space-between;
            padding: 30px 30px;
            background-color: $bg-white;
            transition: all 0.3s ease-in-out;
            box-shadow: $box-shadow-1;
            border-radius: 10px;
            @include breakpoints(laptop) {
              // height: 60px;
            }
            @include breakpoints(tablet) {
              height: auto;
            }
            .LevelIncome_UserIncomeCard_content {
              position: relative;
              z-index: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              transition: all 0.3s ease-in-out;
              justify-content: space-between;
              .LevelIncome_UserIncomeCard_widget_info {
                p {
                  font-size: 20px;
                  color: $text-p-color;
                  white-space: nowrap;
                  // @include breakpoints(semiLargeLaptop) {
                  //   font-size: 28px;
                  // }
                  // @include breakpoints(tablet) {
                  //   font-size: 26px;
                  // }
                  // @include breakpoints(semiMiniTablet) {
                  //   font-size: 24px;
                  // }
                  // @include breakpoints(largeMiniMobile) {
                  //   font-size: 20px;
                  // }
                  // @include breakpoints(mobile) {
                  //   font-size: 18px;
                  // }
                }
                h2 {
                  font-size: 16px;
                  color: $text-p-color;
                  margin-bottom: 10px !important;
                  @include breakpoints(semiLargeLaptop) {
                    // font-size: 16px;
                  }
                }
                a {
                  color: $text-black;
                  font-size: 13px;
                  text-transform: capitalize;
                  font-weight: 500;
                  &:hover {
                    color: $text-menu-active-color;
                  }
                }
              }
              .LevelIncome_UserIncomeCard_widget_icon {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                padding: 0px;
                margin-right: 0px;
                order: 2;
                .LevelIncomeImageIcon {
                  width: 35px;
                  padding: 15px;
                }
                svg {
                  color: #fff;
                  font-size: 30px;
                  border-radius: 50px;
                  padding: 10px;
                }
              }
            }
          }
        }
        table {
          width: 100%;
          box-shadow: $box-shadow-1;
          border-radius: 10px;
          border-collapse: collapse;
          margin: 50px auto;
          text-align: left;
          text-transform: capitalize;
          color: $text-p-color;
          border: $border-2;
          border: 1px solid rgb(224 224 224 / 38%) !important;
          // @include breakpoints(semiTablet) {
          //   width: 320px;
          //   margin: 50px auto;
          // }
          @include breakpoints(mdlgMobile) {
            width: 300px;
            margin: 50px auto;
          }
          @include breakpoints(largeMiniMobile) {
            width: 280px;
            margin: 50px auto;
          }
          @include breakpoints(mobile) {
            width: 230px;
            margin: 50px auto;
          }
          thead {
            tr {
              .main {
                width: 70%;
                padding: 15px 20px;
                border: $border-2;
                color: $text-p-color !important;
                border: 1px solid rgb(224 224 224 / 38%) !important;
                border-left: none !important;
                border-right: none !important;
                text-align: left;
                font-size: 18px;
                position: relative;
                &::before {
                  content: "";
                  position: absolute;
                  width: 4px;
                  height: 40%;
                  background: $text-menu-active-color;
                  top: 18px;
                  left: 10px;
                  bottom: 0;
                }
              }
              th {
                text-align: center;
              }
            }
          }
          tr {
            th,
            td {
              padding: 10px;
              border: $border-2;
              color: $text-p-color !important;
              border: 1px solid rgb(224 224 224 / 38%) !important;
              text-align: left;
              font-size: 18px;
              text-align: center;
            }
            th {
              text-align: left;
              background: $bg-body !important;
              color: $text-p-color !important;
              border-bottom: 1px solid rgb(224 224 224 / 38%) !important;
            }
          }
        }
      }
    }
  }
}
