@import "../../../responsive/breakpoints";
.amount_field {
  position: relative;
  &::after {
    content: "$";
    width: 1em;
    height: 1em;
    position: absolute;
    top: 67%;
    left: 8px;
    transform: translateY(-50%);
  }
  input {
    width: 97% !important;
    padding: 10px 8px;
    padding-left: 23px !important;
  }
}
