@import "./AdminDashboard/index";
@import "./adminAutopool/adminAutopool";
@import "./AdminDepositReport/index";
@import "./AdminEarningTrack/index";
@import "./AdminFundTransfer/index";
@import "./AdminMemberPage/index";
@import "./AdminSetting/index";
@import "./AdminSupport/index";
@import "./AdminWithdrawReport/index";


// Admin chart
.chart__card__area{
  margin-top: 30px;
}
.filter__area {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  .form__group {
    select,
    input {
      padding: 10px;
      color: $text-black;
      background: $default-bg-color;
      border: none;
      outline: none;
      font-size: 15px;
    }
    .dateFilter {
      padding: 11px 15px;
      background: $bg-primary;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      color: #fff;
      font-size: 15px;
    }
  }
}
.chart__area {
  position: relative;
  margin-top: 20px;
  .chart__loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #e4e4e4;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: auto;
      filter: brightness(0.9);
    }
  }
}
