.lna_dash_card_wrapper {
  padding: 1.25rem !important;
  display: grid;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  background: $bg-header;
  box-shadow: 0 0.125rem #0a0a0a0a !important;
  .lna_content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 0.3s ease-in-out;
    justify-content: space-between;
    .lna_widget_info {
      p {
        font-size: 15px !important;
        color: #8c9097 !important;
        text-align: start;
      }
      h2 {
        font-size: 1.5rem !important;
        color: $default-text-color !important;
        margin-bottom: 10px !important;
      }
      .viewAllLink {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 13px;
        span {
          svg {
            margin-bottom: -4px !important;
            font-size: 20px !important;
          }
        }
      }
    }
    .lna_widget_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 0px;
      order: 2;
      @include breakpoints(smLaptop) {
        margin-right: 10px;
      }
      @include breakpoints(largeMiniMobile) {
        margin-right: 0px;
      }
      img {
        width: 25px !important;
        padding: 15px;
      }
      .account_info_image {
        width: 35px !important;
        padding: 15px;
      }
    }
    // .lna_widget_info {
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: center;
    //   align-items: center;
    //   border-radius: 50%;
    //   padding: 0px;
    //   order: 2;
    //   @include breakpoints(smLaptop) {
    //     margin-right: 10px;
    //   }
    //   @include breakpoints(largeMiniMobile) {
    //     margin-right: 0px;
    //   }
    //   img {
    //     width: 25px !important;
    //     padding: 15px;
    //   }
    // }
  }
}
.lna_all_card {
  box-shadow: $box-shadow;
  border-radius: 5px;
  background-color: $bg-header !important;
  .lna_section_title {
    h2 {
      color: $text-default-color;
      transition: all 0.3s ease-in-out;
    }
  }
}
.lna_all_Auth_card {
  box-shadow: 0 5px 5px 0 rgb(82 63 105 / 5%);
  border-radius: 5px;
  background-color: #fff !important;
  .lna_section_title {
    h2 {
      color: #7987a1 !important;
      transition: all 0.3s ease-in-out;
    }
  }
}

.lna_dash_zoom_card_wrapper {
  padding: 10px !important;
  display: grid;
  align-items: center;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  background: $bg-header;
  box-shadow: 0 0.125rem #0a0a0a0a !important;
  .heading {
    display: flex;
    flex-direction: row;
    color: blue;
    margin: 20px;
  }
  .lna_content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    // align-items: center;
    transition: all 0.3s ease-in-out;
    justify-content: space-between;
    .lna_widget_info {
      p {
        font-size: 17px !important;
        color: black !important;
      }
      h2 {
        font-size: 12px !important;
        color: black;
        margin-bottom: 10px !important;
      }
      .viewAllLink {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 13px;
        span {
          svg {
            margin-bottom: -4px !important;
            font-size: 20px !important;
          }
        }
      }
    }
    .lna_widget_icon {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 0px;

      order: 2;
      @include breakpoints(smLaptop) {
        margin-right: 10px;
      }
      @include breakpoints(largeMiniMobile) {
        margin-right: 0px;
      }
      img {
        width: 100px !important;
        padding: 15px;
        border-radius: 60%;
      }
    }
  }
}
