@import "../../responsive/breakpoints";

.sst_home-banner {
  // background-color: $bg_front_black_purple;
  // background-image: url("https://res.cloudinary.com/deavhufn6/image/upload/v1694425310/sst-bg_dchkjr.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  padding: 100px 50px 80px 50px;
  background-color: #fff;

  @include breakpoints(tablet) {
    padding: 100px 10px 50px 10px;
  }

  .sst_hero-container {
    font-family: $font-family;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    @include breakpoints(tablet) {
      flex-direction: column-reverse;
    }

    .sst_herro-left {
      flex: 1;
      margin-right: 30px;
      padding-bottom: 40px;

      @include breakpoints(tablet) {
        margin-right: 0px;
      }

      h4 {
        font-size: 1rem;
        font-weight: 400;
        color: #000;
        background: #0070ec57;
        padding: 6px 10px;
        border-radius: 10px;
        display: inline-block;

        @include breakpoints(tablet) {
          font-size: 12px;
        }
      }

      h1 {
        font-size: 3rem;
        font-weight: 900 !important;
        text-transform: capitalize;
        color: #000;
        padding: 20px 0px;
        @include breakpoints(tablet) {
          font-size: 39px;
          font-weight: 900 !important;
          padding: 10px 0 10px;
          line-height: 1.5em;
        }
      }

      p {
        font-size: 20Fpx;
        font-weight: 300;
        text-transform: capitalize;
        color: #000;
        line-height: 3rem;

        @include breakpoints(tablet) {
          font-size: 15px;
          font-weight: 300 !important;
          /* text-transform: capitalize; */
          color: #000;
          line-height: 2em;
        }
      }

      .sst_hero-buttons {
        margin-top: 30px;

        @include breakpoints(tablet) {
          margin: 20px 0;
        }

        button {
          background-color: #0000ffd1;
          padding: 15px 25px;
          font-size: 20px;
          color: #000;
          border: none;
          margin-right: 20px;
          font-weight: 600 !important;
          transition: all 500ms ease-in-out;

          @include breakpoints(tablet) {
            padding: 10px 15px;
            font-size: 15px;
            margin-right: 10px;
          }

          :hover {
            background-color: rgb(2, 2, 161);
            cursor: pointer;
          }
        }
      }
    }
    .sst_herro-right {
      flex: 1;
      img {
        width: 100%;
        height: 100%;
        -webkit-animation: bounce 2.8s ease-in-out 0s infinite alternate;
        animation: bounce 1.8s ease-in-out 0s infinite alternate;
        @include breakpoints(tablet) {
          width: 400px;
          max-width: 100%;
        }
      }
    }
  }
}
