@import "../../../responsive/breakpoints";
.lna_transferfunds_page_wrapper {
  margin-top: 10px;
  .lna_transferfunds_form_card {
    padding: 25px;
    min-height: 250px;
    .lna_section_title {
      text-align: start;
      margin-bottom: 20px;
      h2 {
        font-size: 20px;
        color: $text-default-color;
        transition: all 0.3s ease-in-out;
        @include breakpoints(tablet) {
          font-size: 20px;
        }
      }
    }
    .lna_transferfunds_page_content {
      form {
        .form_group {
          display: flex;
          margin: 5px 0;
          @include breakpoints(portraitTablet) {
            display: inherit;
            margin: 0px;
          }
          .input_group {
            width: 50%;
            @include breakpoints(portraitTablet) {
              width: auto;
              margin: 10px 0;
            }
          }
          .left {
            margin-right: 33px !important;
            @include breakpoints(portraitTablet) {
              margin-right: 16px !important;
            }
          }
          .right {
            margin-right: 19px;
          }
          label {
            font-size: 15px;
            font-weight: 600;
            color: $text-default-color;
            @include breakpoints(tablet) {
              font-size: 14px;
            }
          }
          .input_field {
            width: 100%;
            padding: 10px 8px;
            color: $text-default-color;
            font-size: 15px;
            border: $border-header;
            outline: none;
            margin: 10px 0;
            border-radius: 5px;
            background: $bg-body;
          }
        }
        .txn_password {
          .right {
            width: 48%;
            @include breakpoints(portraitTablet) {
              width: auto;
            }
          }
        }
        .submit_btn {
          background: $bg-blue-linear-gradient;
          box-shadow: $box-shadow-sidebar-menu;
          padding: 10px 12px;
          width: 130px;
          border-radius: 5px;
          border: none;
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
          color: #fff;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 0.8;
          }
          @include breakpoints(portraitTablet) {
            width: 100%;
          }
        }
      }
    }
  }
}
