.lna_my_profile_page_wrapper {
  margin-top: 10px;
  .lna_my_profile_card {
    padding: 25px;
    min-height: 250px;
    .lna_section_title {
      h2 {
        font-size: 18px;
        text-transform: capitalize;
        color: $text-p-color;
        transition: all 0.3s ease-in-out;
      }
    }
    .lna_profile_head {
      margin-top: 20px;
      .lna_photo_content {
        position: relative;
        .lna_cover_photo {
          background: url("../../../../assets/cover.jpg");
          background-size: cover;
          background-position: 50% 67%;
          min-height: 250px;
          width: 100%;
          border-radius: 20px;
          @include breakpoints(tablet) {
            min-height: 170px;
          }
          @include breakpoints(largeMobile) {
            min-height: 120px;
          }
        }
      }
      .lna_profile_short_info {
        display: flex;
        position: relative;
        padding: 15px 20px;
        @include breakpoints(tablet) {
          display: inherit;
          text-align: center;
        }
        .lna_profile_photo {
          width: 200px;
          height: 200px;
          max-width: 100%;
          position: relative;
          z-index: 1;
          margin-top: -110px;
          margin-right: 20px;
          border-radius: 50%;
          overflow: hidden;
          border: 5px solid #fff;
          @include breakpoints(tablet) {
            text-align: center;
            margin: -110px auto;
          }
          img {
            width: 200px;
            height: 200px;
            border-radius: 50% !important;
            max-width: 100%;
            object-fit: cover;
          }
          img:hover ~ form {
            visibility: visible !important;
            opacity: 1 !important;
            transition: all 0.3s ease-in-out;
          }
          form {
            position: absolute;
            bottom: -2px;
            margin: auto;
            left: 0px;
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s ease-in-out;
            &:hover {
              visibility: visible !important;
              opacity: 1 !important;
              transition: all 0.3s ease-in-out;
            }
            input {
              width: 100%;
              overflow: hidden;
              &::-webkit-file-upload-button {
                visibility: hidden;
              }
              &::before {
                font-family: poppins;
                content: "<>";
                display: inline-block;
                border: 1px solid #999;
                color: #000000;
                border-radius: 3px;
                padding: 8px 8px;
                position: absolute;
                left: -8px;
                outline: none;
                white-space: nowrap;
                -webkit-user-select: none;
                text-align: center;
                user-select: none;
                bottom: 0;
                cursor: pointer;
                text-shadow: 1px 1px #fff;
                font-weight: 700;
                font-size: 10pt;
                width: 100%;
                background: #000000ab;
              }
              @include breakpoints(tablet) {
                margin: 10px 0;
              }
            }
          }
        }
        .lna_profile_info {
          display: flex;
          @include breakpoints(tablet) {
            display: inherit;
            margin-top: 115px;
          }
          .lna_profile_name {
            margin-right: 25px;
            @include breakpoints(tablet) {
              margin-right: 0px;
            }
            h2 {
              font-size: 25px;
              color: $text-primary;
              // font-weight: 600 !important;
              @include breakpoints(mdlgMobile) {
                font-size: 14px;
                padding: 5px 5px;
              }
              @include breakpoints(mdlgMobile) {
                font-size: 14px;
                padding: 5px 5px;
              }
            }
            .profile__status {
              font-size: 13px;
              color: $text-p-color;
              padding: 5px 0;
            }
            .lna__profile__additional__info {
              display: flex;
              align-items: center;
              gap: 10px;
              flex-wrap: wrap;
              @include breakpoints(tablet){
                justify-content: center;
              }
              .profile__email,
              .profile__location,
              .profile__package {
                display: flex;
                align-items: center;
                gap: 5px;
                color: $default-text-color;
                span {
                  svg {
                    font-size: 18px;
                    color: $text-primary;
                    margin-bottom: -3px;
                  }
                }
                p {
                  font-size: 15px;
                  color: $default-text-color;
                }
                &.profile__package {
                  p {
                    text-transform: capitalize;
                  }
                }
              }
            }
          }
        }
        button {
          padding: 4px 8px;
          color: #fff;
          width: 85px;
          font-size: 10px;
          border: none;
          border-radius: 6px;
          margin-right: 10px;
          position: relative;
          display: inline-block;
          cursor: pointer;
          height: 25px;
          align-self: center;
          margin-left: 10px;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 0.8;
          }
        }
        .green {
          background-color: $text-primary;
          box-shadow: $box-shadow;
          border-radius: 2px;
        }
      }
    }
    .lna_profile_bottom {
      margin-top: 15px;
      padding: 15px;
      border-radius: 5px;
      background: $default-bg-color;
      .lna_profile_title {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2 {
          font-size: 18px;
          text-transform: capitalize;
          color: $text-p-color;
          transition: all 0.3s ease-in-out;
        }
        span {
          svg {
            font-size: 20px;
            cursor: pointer;
            color: $text-primary;
            font-weight: 600;
          }
        }
      }
      .lna_profile_field {
        .form_group {
          display: flex;
          margin: 15px 0;
          @include breakpoints(portraitTablet) {
            display: inherit;
            margin: 0px;
          }
          .input_group {
            width: 50%;
            // background: red;
            @include breakpoints(portraitTablet) {
              width: auto;
              margin: 10px 0;
            }
          }
          .left {
            margin-right: 33px !important;
            @include breakpoints(portraitTablet) {
              margin-right: 16px !important;
            }
          }
          .right {
            margin-right: 15px !important;
          }
          label {
            width: 100%;
            font-weight: 600;
            font-size: 13px;
            margin-bottom: 15px !important;
            color: $default-text-color;
            transition: all 0.3s ease-in-out;
            @include breakpoints(tablet) {
              font-size: 14px;
            }
          }
          input {
            width: 100%;
            padding: 10px 8px;
            border: $border-1;
            outline: none;
            border-radius: 5px;
            color: $default-text-color;
            background: $default-bg-color;
            transition: all 0.3s ease-in-out;
            margin-top: 10px;
          }
          input:disabled {
            background-color: $default-bg-color;
            border: none;
            cursor: auto;
            border: $border-1 !important;
          }
          .profile__gender{
            display: flex;
            flex-direction: column;
            width: 50%;
            @include breakpoints(portraitTablet){
              width: auto;
            }
            select{
              width: 100%;
              padding: 10px 8px;
              border: $border-1;
              outline: none;
              border-radius: 5px;
              color: $default-text-color;
              background: $default-bg-color;
              transition: all 0.3s ease-in-out;
            }
          }
          button {
            padding: 20px 25px;
            color: #fff;
            background: $bg-primary;
            width: 134px;
            font-size: 16px;
            border: none;
            border-radius: 6px;
            cursor: pointer;
            height: 32px;
            margin-left: 10px;
            transition: all 0.3s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              opacity: 0.8;
            }
          }
          .copyToClipboard {
            color: $text-primary;
            background-color: $bg-body;
          }
        }
      }
    }
  }
}
