@import "../../../abstract/variables";
@import "../../../responsive/breakpoints";
$color_1: #666;
$color_2: #000;
$border-color_1: #94a0b4;

.lna_structureView__card {
  padding: 20px;
  .lna__structureView__container {
    .lna__structureView__heading {
      display: flex;
      justify-content: space-between;
      h2 {
        font-size: 15px;
        text-align: left;
        color: $text-p-color;
        text-transform: uppercase;
        margin-bottom: 10px !important;
        position: relative;
        // margin-left: 9px !important;
        font-weight: 500 !important;
      }
      .slotWise {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        label {
          color: $text-black;
          font-size: 15px;
        }
      }
    }
    .lna__structureView__filterPanel {
      margin-top: 20px;
      .form__group {
        display: flex;
        gap: 10px;
        @include breakpoints(largeMiniMobile) {
          flex-direction: column;
        }
        .fill__group {
          display: flex;
          flex-direction: column;
          gap: 5px;
          width: 250px;
          max-width: 100%;
          @include breakpoints(largeMiniMobile) {
            width: 100%;
          }
          label {
            color: $text-black;
            font-size: 15px;
          }
          input {
            padding: 10px 12px;
            outline: none;
            border: 1px solid $border-1;
            background: $default-bg-color;
            color: $text-black;
          }
        }
        .userIdSelect {
          display: flex;
          flex-direction: column;
          width: 250px;
          max-width: 100%;
          gap: 5px;
          @include breakpoints(largeMiniMobile) {
            width: 100%;
          }
          label {
            color: $text-black;
            font-size: 15px;
          }
          select {
            padding: 10px 12px;
            outline: none;
            border: 1px solid $border-1;
            background: $default-bg-color;
            color: $text-black;
          }
        }
      }
    }
    .userIdentity {
      margin: 20px 0;
      p {
        color: $text-black;
        font-size: 18px;
        span {
          color: rgb(132, 90, 223);
          font-weight: 600 !important;
        }
      }
    }
    .loader {
      width: 150px;
      height: 150px;
      margin: auto;
      img {
        width: 100%;
      }
    }
    .lna__structureView__tree {
      .tree__header {
        margin: 20px 0;
        text-align: center;
        h2 {
          color: $text-black;
          font-size: 22px;
          font-weight: 500 !important;
        }
      }
      /*Hover-Section*/
      .tree {
        width: 100%;
        height: auto;
        text-align: center;
        overflow-x: auto;
        ul {
          padding-top: 20px !important;
          position: relative;
          transition: 0.5s;
          ul {
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 50%;
              border-left: 1px solid #ccc;
              width: 0;
              height: 20px;
            }
          }
        }
        li {
          display: inline-table;
          text-align: center;
          list-style-type: none;
          position: relative;
          padding: 10px;
          transition: 0.5s;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 50%;
            border-top: 1px solid #ccc;
            width: 51%;
            height: 10px;
          }
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 50%;
            border-top: 1px solid #ccc;
            width: 51%;
            height: 10px;
            right: auto;
            left: 50%;
            border-left: 1px solid #ccc;
          }
          &:only-child {
            &::after {
              display: none;
            }
            &::before {
              display: none;
            }
            padding-top: 0;
          }
          &:first-child {
            &::before {
              border: 0 none;
            }
            &::after {
              border-radius: 5px 0 0 0;
              -webkit-border-radius: 5px 0 0 0;
              -moz-border-radius: 5px 0 0 0;
            }
          }
          &:last-child {
            &::after {
              border: 0 none;
            }
            &::before {
              border-right: 1px solid #ccc;
              border-radius: 0 5px 0 0;
              -webkit-border-radius: 0 5px 0 0;
              -moz-border-radius: 0 5px 0 0;
            }
          }
          a {
            border: 1px solid #ccc;
            padding: 10px;
            display: inline-grid;
            border-radius: 5px;
            text-decoration-line: none;
            border-radius: 5px;
            transition: 0.5s;
            img {
              width: 30px;
              height: 30px;
              margin-bottom: 10px !important;
              border-radius: 100px;
              margin: auto;
            }
            span {
              border: 1px solid #ccc;
              border-radius: 5px;
              color: $text-black;
              padding: 5px;
              font-size: 10px;
              text-transform: uppercase;
              letter-spacing: 1px;
              font-weight: 500;
              font-size: 7px;
            }
            &:hover {
              background: #c8e4f8;
              color: $color_2;
              border: 1px solid #94a0b4;
              i {
                background: #c8e4f8;
                color: $color_2;
                border: 1px solid #94a0b4;
              }
              span {
                background: #c8e4f8;
                color: $color_2;
                border: 1px solid #94a0b4;
              }
            }
            &:hover + ul {
              li {
                a {
                  background: #c8e4f8;
                  color: $color_2;
                  border: 1px solid #94a0b4;
                }
                &::after {
                  border-color: $border-color_1;
                }
                &::before {
                  border-color: $border-color_1;
                }
              }
              &::before {
                border-color: $border-color_1;
              }
              ul {
                &::before {
                  border-color: $border-color_1;
                }
              }
            }
          }
        }
      }
    }
  }
}
