.kyc_page_wrapper {
  margin-top: 10px;
  @include breakpoints(tablet) {
    margin-top: 5px !important;
  }
  .kyc_form_card {
    padding: 15px;
    min-height: 250px;
    @include breakpoints(tablet) {
      min-height: auto !important;
    }
    // Section Title
    .kyc_section_title {
      text-align: start;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include breakpoints(extralargeMobile) {
        margin-bottom: 10px !important;
      }
      h2 {
        font-size: 18px;
        text-align: left;
        color: $text-p-color;
        text-transform: uppercase;
        margin-bottom: 10px !important;
        position: relative;
        margin-left: 10px !important;
        font-weight: 500 !important;
        &::before {
          content: "";
          position: absolute;
          width: 3px;
          height: 90%;
          background: $bg-primary;
          top: 1px;
          left: -8px;
          bottom: 0;
        }
        @include breakpoints(extralargeMobile) {
          font-size: 15px;
        }
      }
    }
    .kyc_page_content {
      label {
        color: $text-black !important;
        @include breakpoints(extralargeMobile) {
          font-size: 12px !important;
        }
      }
      .select_field {
        width: 100%;
        padding: 10px 8px;
        border: $border-header;
        outline: none;
        border-radius: 5px;
        color: $text-black;
        background: $default-bg-color;
        margin-top: 10px;
        @include breakpoints(extralargeMobile) {
          padding: 7px 5px !important;
          margin-top: 0px !important;
          font-size: 12px !important;
        }
      }
      form {
        .images_section {
          display: flex;
          justify-content: space-between;
          margin-top: 20px;
          margin-bottom: 20px;
          gap: 20px;
          @include breakpoints(portraitTablet) {
            flex-direction: column !important;
            margin-top: 10px !important;
            margin-bottom: 10px !important;
            gap: 5px !important;
          }
          .kyc_box {
            width: 100%;
            @include breakpoints(portraitTablet) {
              margin-bottom: 20px;
            }
            @include breakpoints(extralargeMobile) {
              margin-bottom: 0px !important;
            }
            .image_preview {
              width: 100%;
              height: 300px;
              margin-bottom: 10px;
              @include breakpoints(largeMobile) {
                height: 200px;
              }
              img {
                height: 100%;
                // width: 100%;
                border-radius: 15px;
                object-fit: cover;
              }
            }
            .form_group {
              display: flex;
              flex-direction: column;
              p {
                font-size: 10px !important;
              }
              label {
                font-size: 13px;
                font-weight: 600;
                color: $text-p-color;
              }
              input {
                // width: 100%;
                padding: 10px 8px;
                border: $border-header;
                outline: none;
                border-radius: 5px;
                color: $text-p-color;
                background: $default-bg-color;
                margin-top: 10px;
                position: relative;
                @include breakpoints(extralargeMobile) {
                  padding: 3px 5px !important;
                  margin-top: 0px !important;
                }
              }
            }
          }
        }
        .form_group {
          display: flex;
          flex-direction: column;
          .input_field {
            // width: 100%;
            padding: 10px 8px;
            border: $border-header;
            outline: none;
            border-radius: 5px;
            color: $text-p-color;
            background: $default-bg-color;
            margin-top: 10px;
            position: relative;
            @include breakpoints(extralargeMobile) {
              margin-top: 0px !important;
              font-size: 12px !important;
            }
          }
          label {
            font-size: 13px;
            font-weight: 600;
            color: $default-text-color;
          }
        }

        .submit_btn {
          background: $bg-primary;
          padding: 10px 12px;
          width: 140px;
          border-radius: 5px;
          border: none;
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
          color: #fff;
          margin-top: 20px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .deposithistory_table {
    .tp_sectiontable_card {
      @include breakpoints(tablet) {
        padding: 5px !important;
      }
      .tp_sectiontable_title {
        @include breakpoints(extralargeMobile) {
          margin-bottom: 5px !important;
        }
      }
    }
  }
}
