.mission_card {
    background-color: #3F3453;
    padding: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 15px;

    h4 {
        width: 40px;
        height: 40px;
        font-size: 30px;
        font-weight: 500 !important;
        color: #fff;
        border: 5px solid #fff;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    h3 {
        font-size: 20px;
        font-weight: 60 !important;
        color: #fff;
        padding: 15px 0px;
        text-align: center;
    }

    p {
        font-size: 15px;
        font-weight: 400 !important;
        color: #fff;
        text-align: center;
    }
}

// do card
.do_card {
    background-color: #4169e1;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    gap: 10px;


    h3 {
        font-size: 20px;
        font-weight: 60 !important;
        color: #fff;
        padding: 15px 0px;
        text-align: center;
    }

    .left_icon{
        font-size: 40px;
        color: #fff;
        display: inline-block;
        border: 1px solid #fff;
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .description{
        color: #fff;
        text-align:center;
        font-size: 15;
    }

    .right_icon{
        font-size: 30px;
        color: #fff;
        display: inline-block;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}