.lna_sectiontable_title {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @include breakpoints(tablet) {
    display: inherit;
  }
  .lna_sectiontable_title_container {
    @include breakpoints(tablet) {
      margin-bottom: 10px;
    }
    h2 {
      font-size: 15px;
      text-align: left;
      color: $text-p-color;
      text-transform: uppercase;
      margin-bottom: 10px !important;
      position: relative;
      margin-left: 9px !important;
      font-weight: 500 !important;
      &::before {
        content: "";
        position: absolute;
        width: 3px;
        height: 87%;
        background: $text-menu-active-color;
        top: 1px;
        left: -8px;
        bottom: 0;
      }
    }
    .lna_giftAllButton {
      margin-right: 5px;
      border: none;
      border-radius: 5px;
      padding: 5px 10px;
      color: white;
      cursor: pointer;
      background-color: rgb(41 156 13);
    }
    :disabled {
      background: #9b9696 !important;
      cursor: no-drop !important;
    }
    .lna_sectiontable_balance {
      h2 {
        font-size: 20px;
        color: $text-default-color;
        background-color: $bg-menu-active-color;
        border-radius: 50px;
        padding: 10px 30px;
        @include breakpoints(tablet) {
          margin-top: 10px !important;
        }
      }
    }
  }
  .left {
    display: flex;
    align-items: center;
    @include breakpoints(largeMiniMobile) {
      flex-direction: column !important;
    }
    .downloadCSV_button {
      border: none;
      background-color: $bg-thin-green;
      padding: 5px 10px;
      border-radius: 5px;
      margin-right: 10px;
      color: white;
    }
    .select_input_box {
      margin-left: 10px;
      .select_input {
        padding: 10px;
        border-radius: 5px;
        outline: none;
        color: $text-p-color;
        background: $bg-body;
        font-size: 14px;
        border: none;
        border: $border-header;
        min-width: 80px;
      }
    }
    .searchbar_input {
      .spacial_search_input {
        padding: 10px;
        border-radius: 5px;
        outline: none;
        color: $text-p-color;
        background: $bg-body;
        font-size: 14px;
        border: none;
        border: $border-header;
        min-width: 200px;
      }
    }
    .send-all {
      width: 130px;
      padding: 10px 8px;
      height: max-content;
      background: $bg-thin-green;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      border: none;
      text-transform: capitalize;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      margin-right: 10px;
      @include breakpoints(largeMiniMobile) {
        margin-bottom: 10px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.lna_containers {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include breakpoints(tablet) {
    display: inherit;
  }
  .lna_input_fields {
    width: 100%;
    @include breakpoints(tablet) {
      width: 100%;
    }
    .fields {
      margin-top: 20px;
      .form_group {
        margin: 20px 0;
        .right {
          margin-right: 19px !important;
        }
        label {
          width: 100%;
          font-weight: 500;
          font-size: 13px;
          margin-bottom: 10px !important;
          color: $text-p-color;
        }
        input {
          width: 100%;
          padding: 10px 8px;
          border: $border-header;
          border-radius: 5px;
          outline: none;
          color: $text-p-color;
          background: $bg-body;
          margin-top: 10px;
        }
      }
    }
  }
  .lna_message_box {
    //   padding: 0 20px;
    width: 100%;
    margin-top: 20px;
    @include breakpoints(tablet) {
      width: 100%;
      border: none;
      padding: 0px;
    }
    label {
      color: $text-p-color;
      font-weight: 500;
      font-size: 13px;
    }
    .message_box {
      width: 100%;
      resize: none;
      border: none;
      outline: none;
      margin-top: 10px;
      padding-top: 10px;
      padding-left: 10px;
      font-family: $font-family;
      min-height: 202px;
      background: $bg-body;
      color: $text-p-color;
      border: $border-header;
      border-radius: 5px;
      @include breakpoints(tablet) {
        min-height: 50px;
      }
    }
  }
}
.send_button {
  width: max-content;
  padding: 10px 20px;
  text-align: center;
  background: $bg-thin-green;
  box-shadow: $box-shadow-sidebar-menu;
  font-size: 15px;
  border: none;
  outline: none;
  text-transform: capitalize;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 10px;
  @include breakpoints(tablet) {
    width: 100%;
  }
  &:hover {
    opacity: 0.8;
  }
}
