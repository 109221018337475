@mixin title {
  text-align: start;
  margin-bottom: 20px;
  h2 {
    font-size: 22px;
    text-transform: capitalize;
    color: $default-text-color;
    transition: all 0.3s ease-in-out;
  }
  p {
    font-size: 15px;
    color: $default-text-color;
  }
}
@mixin card {
  padding: 25px;
  min-height: 250px;
}
@mixin form {
  .form_group {
    display: flex;
    margin: 15px 0;
    align-items: flex-end;
    @include breakpoints(portraitTablet) {
      display: inherit;
      margin: 0px;
    }
    span {
      color: $text-p-color;
      transition: all 0.3s ease-in-out;
      strong {
        color: $text-primary;
        font-weight: 600;
        cursor: pointer;
        user-select: none;
      }
    }
    .left {
      margin-right: 33px !important;
      @include breakpoints(portraitTablet) {
        margin-right: 16px !important;
      }
    }
    .right {
      margin-right: 15px !important;
    }
    .input_group {
      width: 50%;
      @include breakpoints(portraitTablet) {
        width: auto;
        margin: 10px 0;
      }
      label {
        width: 100%;
        font-weight: 600;
        font-size: 13px;
        margin-bottom: 10px !important;
        color: $text-p-color;
        transition: all 0.3s ease-in-out;
        @include breakpoints(tablet) {
          font-size: 14px;
        }
      }
      input {
        width: 100%;
        padding: 10px 8px;
        border: $border-header;
        outline: none;
        border-radius: 5px;
        color: $text-p-color;
        background: $default-bg-color;
        margin-top: 10px;
      }
      input:disabled {
        background-color: $bg-body;
        border: none;
        cursor: no-drop;
      }
    }
    .generate_password {
      margin-left: 20px;
      @include breakpoints(portraitTablet) {
        margin-left: 0px;
      }
    }

    button {
      width: 130px;
      padding: 10px 8px;
      height: max-content;
      background: $bg-primary;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      border: none;
      text-transform: capitalize;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 0.8;
      }
      @include breakpoints(portraitTablet) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    .OTP_getting_button {
      width: 130px;
      padding: 10px 8px;
      background: $bg-thin-green;
      box-shadow: $box-shadow-sidebar-menu;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
      border: none;
      text-transform: capitalize;
      border-radius: 3px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 0.8;
      }
      @include breakpoints(portraitTablet) {
        width: 100%;
        margin-bottom: 15px;
      }
    }
    .OTP_getting_resend_button {
      width: 90px;
      height: 17px;
      padding: 10px 8px;
      justify-self: end;
      align-self: end;
      // @include breakpoints(portraitTablet) {
      //   margin-bottom: 25px;
      // }
    }
  }
  .otp_form_group {
    @include breakpoints(portraitTablet) {
      margin-bottom: 25px;
    }
  }
  .show_password {
    display: flex !important;
    align-items: center;
    flex-direction: row;

    .form-check-input {
      display: inline;
    }

    .input_group {
      width: auto !important;
    }
  }
  .form_group_OTP {
    // background-color: red;
    width: 50%;
    @include breakpoints(portraitTablet) {
      width: 100%;
    }
    display: grid;
    grid-template-columns: 7fr 3fr;
    @include breakpoints(mdlgMobile) {
      grid-template-columns: 3fr 2fr;
    }
    gap: 5px;
    .OTP_input_field {
      width: 167% !important;
      @include breakpoints(4kdevice) {
        width: 190% !important;
      }
      @include breakpoints(xtraLargeLaptop) {
        width: 189% !important;
      }
      @include breakpoints(largeLaptop) {
        width: 187% !important;
      }
      @include breakpoints(semiLargeLaptop) {
        width: 185% !important;
      }
      @include breakpoints(laptop) {
        width: 180% !important;
      }
      @include breakpoints(smLaptop) {
        width: 175% !important;
      }
      @include breakpoints(semiMiniTablet) {
        width: 173% !important;
      }
      @include breakpoints(semiTablet) {
        width: 171% !important;
      }
      @include breakpoints(miniSemiTablet) {
        width: 169% !important;
      }
      @include breakpoints(tablet) {
        width: 164% !important;
      }
      @include breakpoints(portraitTablet) {
        width: 100% !important;
      }
    }
    .OTP_resend_btn {
      background: $bg-thin-green;
      box-shadow: $box-shadow-sidebar-menu;
      padding: 0px 12px;
      margin-top: -1px;
      justify-self: end;
      height: 36px;
      align-self: flex-end;
      white-space: nowrap;
      border-radius: 3px;
      border: none;
      color: $text-white;
      font-size: 14px;
      cursor: pointer;
      margin-bottom: 1px !important;
      transition: all 0.3s ease-in-out;
      @include breakpoints(portraitTablet) {
        font-size: 12px;
        margin-bottom: 11px !important;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.lna_updatepassword_page_wrapper {
  margin-top: 20px;
  .lna_accountpassword_card {
    @include card();
    .lna_accountpassword_title {
      @include title();
    }
    .lna_accountpassword_field {
      form {
        .form_group {
          padding: 20px 0;
          display: flex;
          @include breakpoints(tablet){
            flex-direction: column;
            align-items: flex-start;
          }
          width: 100%;
          align-items: center;
          gap: 5px;
          p {
            flex-basis: 30%;
            font-size: 16px;
            font-weight: 500;
            color: $default-text-color;
          }
          label {
            color: $text-p-color;
            font-size: 15px;
            font-weight: 600;
            @include breakpoints(tablet) {
              font-size: 14px;
            }
          }
          .input_field {
            width: 88%;
            padding: 15px 14px;
            border: none;
            outline: none;
            border-radius: 5px;
            font-size: 16px;
            background-color: $default-bg-color;
            color: $default-text-color;
            border: $border-1;
            &::placeholder {
              color: $default-text-color;
            }
          }
          .form-check-input {
            width: 25px;
            height: 20px;
            margin-right: 5px;
          }
          .form-check-label {
            font-size: 18px;
            text-transform: capitalize;
          }
        }
        .area__border {
          border-bottom: 1px solid $border-1;
        }
        .form_group_OTP {
          width: 345px;
          max-width: 100%;
          display: flex;
          gap: 10px;
          @include breakpoints(mdlgMobile) {
            grid-template-columns: 3fr 2fr;
          }
          .OTP_resend_btn {
            background-color: $bg-primary;
            width: 120px;
            max-width: 100%;
            align-self: flex-end;
            white-space: nowrap;
            border-radius: 3px;
            border: none;
            color: #fff;
            font-size: 15px;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
          }
        }
        .submit_btn {
          background-color: $bg-primary;
          padding: 15px 12px;
          width: 130px;
          max-width: 100%;
          max-width: 100%;
          border-radius: 3px;
          border: none;
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
          color: #fff;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
  }
}
