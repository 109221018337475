@import "../../responsive/breakpoints";
.ss-trade_frontpage_wrapper {
  background-color: #1e0b56 !important;
  padding-top: 100px;
  .ss-trade_front_hero_section {
    font-family: $font-family;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoints(tablet) {
      display: inherit;
    }
    .ss-trade_front_hero_content {
      width: 50%;
      @include breakpoints(tablet) {
        width: 100%;
        text-align: center;
        margin-bottom: 50px;
      }
      h2 {
        color: #fff;
        font-size: 50px;
        font-weight: 600;
        text-transform: capitalize;
        line-height: 65px;
        @include breakpoints(tablet) {
          font-size: 35px;
          line-height: inherit;
        }
        @media screen and (max-width: 490px) {
          font-size: 25px;
        }
      }
      p {
        color: #fff;
        font-size: 16px;
        margin: 20px 0 !important;
        margin-bottom: 40px !important;
        font-weight: 600;
        @include breakpoints(tablet) {
          font-size: 13px;
        }
      }
      a {
        padding: 12px 35px !important;
        transition: all 0.3s ease-in-out;
        background-color: #e8c743;
        border-radius: 2px;
        color: #7b5eea !important;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 16px;
        &:hover {
          color: #fff !important;
          background-color: #c4a50c;
          border-bottom: none !important;
        }
      }
    }
    .ss-trade_front_hero_img {
      width: 50%;
      @include breakpoints(tablet) {
        width: 100%;
        text-align: center;
      }
      img {
        -webkit-animation: bounce 1.8s ease-in-out 0s infinite alternate;
        animation: bounce 1.8s ease-in-out 0s infinite alternate;
        @include breakpoints(tablet) {
          width: 400px;
          max-width: 100%;
        }
      }
    }
  }
  .skew_divider {
    width: 100%;
    height: 100px;
    left: 0;
    background: linear-gradient(to right bottom, #1e0b56 49%, #0f052c 50%),
      linear-gradient(-50deg, #fff 16px, #000 0);
  }
}

// bussiness card
.ss-trade_front_businesscard_wrapper {
  background-color: #1e0b56;
  padding-top: 80px;
  padding-bottom: 80px;
  .service_title {
    font-size: 50px;
    text-align: center;
    color: #fff;
    padding: 80px 0px;
    @include breakpoints(mdlgMobile) {
      font-size: 25px;
    }
  }
  .ss-trade_front_business_card {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    @include breakpoints(semiTablet) {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
    }
    @include breakpoints(tablet) {
      grid-column-gap: 20px;
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .ss-trade_front_card {
      background-color: #7b5eea;
      position: relative;
      padding: 25px 15px;
      text-align: center;
      border-radius: 10px;
      transition: all 0.3s ease-in-out;
      @include breakpoints(tablet) {
        padding: 30px 18px;
      }
      &:hover {
        background-color: #fff;
        transform: translateY(-10px);
        box-shadow: 0 8px 60px -6px rgb(123 94 234 / 70%);
        .card_title {
          color: #000;
        }
        .card_desc {
          color: #000;
        }
      }
      .card_img {
        text-align: center;
        img {
          width: 120px;
        }
      }
      .card_title {
        margin-top: 20px;
        margin-bottom: 20px;
        color: #fff;
        transition: all 0.3s ease-in-out;
        h2 {
          font-size: 25px;
          text-align: center;
          font-weight: 600;
          @media screen and (max-width: 490px) {
            font-size: 20px;
          }
        }
      }
      .card_desc {
        color: #fff;
        transition: all 0.3s ease-in-out;
        p {
          font-size: 14px;
          font-family: $font-family;
        }
      }
    }
  }
}
// invest section
.ss-trade_front_investsection_wrapper {
  background-color: #0e0524;
  padding-top: 80px;
  padding-bottom: 80px;
  .ss-trade_front_invest_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include breakpoints(tablet) {
      display: inherit;
    }
    .ss-trade_front_invest_left {
      width: 50%;
      @include breakpoints(tablet) {
        width: 100%;
        margin-bottom: 50px;
        text-align: center;
      }
      h2 {
        font-size: 55px;
        font-weight: 600;
        color: #fff;
        @include breakpoints(tablet) {
          font-size: 35px;
          line-height: inherit;
        }
        @media screen and (max-width: 490px) {
          font-size: 25px;
        }
      }
      p {
        font-size: 14px;
        color: #fff;
        font-weight: 600;
        margin-top: 10px !important;
        margin-bottom: 40px !important;
        @include breakpoints(tablet) {
          font-size: 13px;
        }
      }
      a {
        padding: 12px 35px !important;
        transition: all 0.3s ease-in-out;
        background-color: #7b5eea;
        border-radius: 25px;
        color: #fff !important;
        text-transform: capitalize;
        font-weight: 600;
        font-size: 16px;
        &:hover {
          color: #7b5eea !important;
          background-color: #fff;
          border-bottom: none !important;
        }
      }
    }
    .ss-trade_front_invest_right {
      width: 50%;
      @include breakpoints(tablet) {
        width: 100%;
        text-align: center;
      }
      img {
        max-width: 100%;
        -webkit-animation: bounce 1.8s ease-in-out 0s infinite alternate;
        animation: bounce 1.8s ease-in-out 0s infinite alternate;
        @include breakpoints(tablet) {
          width: 400px;
          max-width: 100%;
        }
      }
    }
  }
}
// faq section
.ss-trade_front_faq_wrapper {
  background: linear-gradient(#490bbd, #9b048ede);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 80px;
  padding-bottom: 80px;
  .title {
    text-align: center;
    h2 {
      font-size: 50px;
      color: #fff;
      font-weight: 600;
      @media screen and (max-width: 490px) {
        font-size: 25px;
      }
    }
  }
  .ss-trade_front_accordion_lists {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    width: 70%;
    margin: auto;
    margin-top: 30px;
    @include breakpoints(tablet) {
      width: 100%;
    }
    .ss-trade_accordion_card {
      .ques {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #0f0f12;
        padding: 12px 5px;
        transition: all 0.3s ease-in-out;
        border-bottom: 2px solid #ccc;
        cursor: pointer;
        &:hover {
          background-color: #0f0f12;
          span {
            svg {
              color: #fff;
            }
          }
        }
        h2 {
          font-size: 20px;
          color: #fff;
          font-weight: 500 !important;
          font-family: $font-family;
          line-height: 2em;
          padding-left: 10px !important;
        }
        span {
          svg {
            color: #ccc;
            font-size: 22px;
            font-weight: 600;
            margin-bottom: -6px;
            transition: all 0.3s ease-in-out;
          }
        }
      }
      .ans {
        padding: 10px 8px;
        p {
          font-size: 15px;
          font-weight: 300 !important;
          font-family: $font-family;
          color: #eee;
          line-height: 2em;
          padding-left: 10px;
        }
      }
    }
  }
}
