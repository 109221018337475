@import "../../../responsive/breakpoints";
.lna_adminHome_wrapper {
  transition: all 0.3s ease-in-out;
  .lna_section_title {
    h2 {
      font-size: 20px;
      color: $text-p-color;
      transition: all 0.3s ease-in-out;
    }
  }
  .lna_dash_content_item {
    width: 100%;
    .lna_dash_content_heading{
      margin-top: 20px;
      border-bottom: $border-header;
      padding-bottom: 5px;
      p{
        font-size: 15px;
        color: $default-text-color;
        text-align: start;
      }
    }
    .lna_dash_content {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      margin-top: 20px;
      grid-row-gap: 15px;
      grid-column-gap: 15px;
      @include breakpoints(smLaptop) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include breakpoints(4kdevice) {
        grid-template-columns: repeat(4, 1fr);
      }
      @include breakpoints(portraitTablet) {
        grid-template-columns: repeat(1, 1fr);
      }

    }
    .lna_dashboard_chart {
      margin-top: 20px !important;
      margin-right: 10px !important;
      padding-top: 10px !important;
      @include breakpoints(largeLaptop) {
        margin-right: 0px !important;
      }
    }
    // refer copy link
    .lna_link_copy_section {
      margin-top: 20px;
      margin-bottom: 10px;
      border-radius: 5px;
      background-color: $bg-white;
      form {
        display: flex;
        align-items: center;
        transition: all 0.3s ease-in-out;
        @include breakpoints(smLaptop) {
          display: inherit;
        }
        .lna_input_field {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 25px 25px;

          @include breakpoints(smLaptop) {
            width: auto;
          }

          @include breakpoints(largeMobile) {
            display: inherit;
            text-align: center;
            padding: 25px 10px;
          }
          .input_group {
            width: 100%;
            Input {
              font-size: 15px;
              padding: 18px 10px;
              width: 100%;
              outline: none;
              border-left: $border-4;
              border-top: $border-4;
              border-bottom: $border-4;
              border-right: none;
              color: $text-gray;
              background-color: $bg-body;
              border-radius: 5px;
              transition: all 0.3s ease-in-out;
              @include breakpoints(largeMobile) {
                width: 90%;
              }
            }
          }
          button {
            font-size: 15px;
            padding: 19px 8px;
            outline: none;
            width: 200px;
            border: none !important;
            text-transform: capitalize;
            background-color: $bg-thin-green;
            color: #fff;
            transition: all 0.3s ease-in-out;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
            @include breakpoints(largeMobile) {
              margin-top: 20px;
            }
          }
        }
      }
    }
  }
}
