@import "../../../abstract/variables";
@import "../../../responsive/breakpoints";
.lna_topupaccount_page_wrapper {
  margin-top: 10px;
  .lna_topupaccount_form_card {
    padding: 15px;
    min-height: 250px;
    .lna_section_title {
      text-align: start;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include breakpoints(portraitTablet) {
        display: flex;
      }
      h2 {
        font-size: 18px;
        color: $text-p-color;
        white-space: nowrap;
        @include breakpoints(mdlgMobile) {
          font-size: 14px;
        }
      }
      .lna__section__wish__status {
        display: flex;
        align-items: center;
        gap: 5px;
        .wish__status {
          padding: 5px 8px;
          border-radius: 5px;
          color: #fff;
        }
      }
      .lna_section_title_right_side {
        display: flex;
        gap: 5px;
        @include breakpoints(miniPortraitTablet) {
          flex-direction: column;
          gap: 2px;
        }
        .lna_section_title_balance {
          text-align: start;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          @include breakpoints(miniPortraitTablet) {
            margin-bottom: 4px;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 15px;
            gap: 10px;
          }
          h3 {
            font-size: 18px;
            color: $text-p-color;
          }
          p {
            color: #24d5b8 !important;
            background-color: rgba(26, 156, 134, 0.2) !important;
            padding: 5px 10px;
            border-radius: 50px;
            white-space: nowrap;

            @include breakpoints(miniPortraitTablet) {
              font-size: 12px;
              padding: 5px 5px;
            }
          }
        }
        .lna_section_Trx_balance {
          p {
            @include breakpoints(mdlgMobile) {
              font-size: 12px;
              padding: 5px 5px;
            }
            white-space: nowrap;
            color: #248ed5 !important;
            background-color: rgba(26, 121, 156, 0.2) !important;
          }
        }
      }
    }
    .lna_topupaccount_page_content {
      form {
        .form_group {
          display: flex;
          margin: 15px 0;
          @include breakpoints(portraitTablet) {
            display: inherit;
            margin: 0px;
          }
          .input_group {
            width: 50%;
            @include breakpoints(portraitTablet) {
              width: auto;
              margin: 10px 0;
            }
          }
          .left {
            margin-right: 33px !important;
            @include breakpoints(portraitTablet) {
              margin-right: 16px !important;
            }
          }
          .right {
            margin-right: 20px !important;
          }
          label {
            font-size: 13px;
            font-weight: 600;
            color: $text-p-color;
          }
          .input_field {
            width: 100%;
            padding: 10px 8px;
            border: $border-header;
            outline: none;
            border-radius: 5px;
            color: $text-p-color;
            background: $default-bg-color;
            margin-top: 10px;
            position: relative;
          }
          .package {
            width: 50%;
            @include breakpoints(portraitTablet) {
              width: 100%;
            }
          }
          .select_field {
            width: 100%;
            padding: 10px 8px;
            border: $border-header;
            outline: none;
            border-radius: 5px;
            color: $text-default-color;
            background: $default-bg-color;
            margin-top: 10px;
          }
          .user_top_up {
            margin-left: 10px !important;
            @include breakpoints(portraitTablet) {
              margin-left: 0px !important;
            }
          }
        }
        .form_group_OTP {
          width: 50% !important;
          @include breakpoints(portraitTablet) {
            display: flex;
            column-gap: 10px;
            width: 100% !important;
          }
          .OTP_resend_btn {
            background: $bg-thin-green;
            box-shadow: $box-shadow-sidebar-menu;
            padding: 10px 12px;
            width: 120px;
            border-radius: 5px;
            border: none;
            font-size: 15px;
            font-weight: 600;
            text-transform: capitalize;
            color: #fff;
            margin-top: 10px;
            @include breakpoints(portraitTablet) {
              margin-bottom: 10px;
            }
            align-self: end;
            height: 38px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            &:hover {
              opacity: 0.8;
            }
            // @include breakpoints(portraitTablet) {
            //   width: 100%;
            // }
          }
        }
        .submit_btn {
          background: $bg-primary;
          padding: 10px 12px;
          width: 120px;
          border-radius: 5px;
          border: none;
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
          color: #fff;
          margin-top: 10px;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          &:hover {
            opacity: 0.8;
          }
          // @include breakpoints(portraitTablet) {
          //   width: 100%;
          // }
        }
      }
    }
  }
}

.lna_topupaccount_page_content {
  .lna_topup_qr_content {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: row;

    @include breakpoints(tablet) {
      flex-direction: column;
    }
    .qr_code_box {
      width: 410px;
      // border: $border-header;
      padding: 15px 10px;
      margin: auto;
      border-radius: 5px;
      @include breakpoints(portraitTablet) {
        width: 100%;
      }
      .qr_img {
        text-align: center;
        img {
          width: 200px;
          height: 200px;
        }
      }
      .qr_share {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px auto !important;
        background: $bg-body;
        border: $border-header;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        span {
          color: $text-p-color;
          font-size: 13px;
          svg {
            color: $text-primary !important;
            margin-top: 7px;
            margin-right: 5px;
          }
        }
      }
      .qr_address {
        position: relative;
        .left {
          margin-right: 18px !important;
          // @include breakpoints(portraitTablet) {
          //   margin-right: 16px !important;
          // }
        }
        .right {
          margin-right: 15px !important;
        }
        span {
          position: absolute;
          top: 45px;
          right: 8px;
          z-index: 999;
          font-size: 20px;
          svg {
            color: $text-primary;
            cursor: pointer;
          }
          background-color: $bg-body;
        }
        label {
          width: 100%;
          font-weight: 600;
          font-size: 13px;
          margin-bottom: 15px !important;
          color: $text-p-color;
          transition: all 0.3s ease-in-out;
          @include breakpoints(tablet) {
            font-size: 14px;
          }
        }
        input {
          width: 100%;
          padding: 10px 8px;
          border: $border-1;
          outline: none;
          border-radius: 5px;
          color: $default-text-color;
          background: $default-bg-color;
          transition: all 0.3s ease-in-out;
          margin-top: 10px;
        }
        input:disabled {
          background-color: $default-bg-color;
          border: $border-header !important;
          border: none;
          cursor: no-drop;
        }
      }
      .disclaimer {
        margin-top: 20px;
        .dis_alert {
          display: flex;
          align-items: center;
          border-bottom: $border-header;
          margin-bottom: 10px;
          span {
            color: rgb(254, 0, 0);
            margin-right: 5px;
            font-size: 13px;
            svg {
              color: rgb(254, 0, 0);
              margin-top: 6px;
            }
          }
        }
        ul {
          li {
            font-size: 13px;
            font-style: italic;
            margin-left: 16px;
            color: $text-p-color;
          }
        }
      }
    }
  }
}
