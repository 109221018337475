* {
  margin: 0;
  padding: 0;
  font-weight: 500 !important;
  // box-sizing: border-box;
}
a {
  text-decoration: none !important;
}
ul {
  margin: 0 !important;
  padding: 0 !important;
}
p {
  margin: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0;
  margin-right: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0 !important;
  margin: 0 !important;
  margin-bottom: 0 !important;
  font-family: $font-family !important;
}
