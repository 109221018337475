@import "../../responsive/breakpoints";
.lna_dashboard_register_page_wrapper {
  padding-top: 50px;
  padding-bottom: 180px;
  background-image: url("https://res.cloudinary.com/deavhufn6/image/upload/v1709631935/bg_s4k7yf.png");
  background-repeat: no-repeat;
  background-size: cover;
  .lna_dashboard_register_card {
    min-height: 420px;
    width: 85%;
    margin: auto;
    position: relative;
    z-index: 9;
    .register_header {
      height: 40px;
      width: auto;
      padding: 10px;
      background-color: #F15151;
      display: flex;
      justify-content: center;
      color: white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      h5 {
        margin-bottom: 0.5rem;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
        color: inherit;
      }
    }
    @include breakpoints(laptop) {
      width: 50%;
    }
    @include breakpoints(tablet) {
      width: 100%;
    }
    .lna_section_title {
      text-align: center;
      margin-bottom: 20px;
      color: #4a4a69;
      h2 {
        font-size: 30px;
        @include breakpoints(laptop) {
          font-size: 25px;
        }
        @include breakpoints(tablet) {
          font-size: 20px;
        }
      }
    }
    .login_page_social_icon {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 20px 0;
      .social_icon {
        list-style-type: none;
        padding: 0 15px;
        font-size: 28px;
        color: rgb(155, 150, 150);
        cursor: pointer;
      }
      .facebook {
        &:hover {
          color: #1877f2;
        }
      }
      .twitter {
        &:hover {
          color: #25d366;
        }
      }
      .instagram {
        &:hover {
          color: #e4405f;
        }
      }
      .youtube {
        &:hover {
          color: #cd201f;
        }
      }
    }
    .hr_border {
      height: 2px;
      width: 100%;
      background-color: #7b5eeaa6;
      margin-bottom: 5px;
    }
    .lna_dashboard_register_content {
      form {
        .form_group {
          margin: 15px 0;
          // display: grid;
          grid-template-columns: repeat(1, 1fr);
          @include breakpoints(portraitTablet) {
            grid-template-columns: repeat(1, 1fr);
            grid-row-gap: 12px;
          }
          .PhoneInput--focus {
            background-color: rgba(123, 94, 234, 0.7) !important;
            color: #fff !important;
          }
          .PhoneInput {
            padding: 9px 6px !important;
            border-radius: 3px !important;
            background-color: rgba(123, 94, 234, 0.08);
            border: none !important;
            &:focus {
              background-color: rgba(123, 94, 234, 0.7);
              color: #fff;
              &::placeholder {
                color: #fff;
              }
            }
            input {
              border: none !important;
              background-color: rgb(123 94 234 / 0%);
              outline: none !important;
              color: #fff;
              &:focus {
                // background-color: rgba(123, 94, 234, 0.7);
                color: #fff;
                &::placeholder {
                  color: #fff;
                }
              }

              &::placeholder {
                text-transform: uppercase;
                color: #9fa4a6;
                font-size: 11px;
              }
            }
          }
          .right {
            margin-right: 19px;
          }
          .left {
            margin-right: 25px;
          }
          label {
            color: #fff;
            font-size: 15px;
            font-weight: 600;
            @include breakpoints(tablet) {
              font-size: 14px;
            }
          }
          .input_field {
            width: 100%;
            padding: 10px 8px;
            border: none;
            outline: none;
            border-radius: 3px;
            color: #9fa4a6;
            background-color: rgba(123, 94, 234, 0.08);
            &:focus {
              background-color: rgba(123, 94, 234, 0.7);
              color: #fff;
              &::placeholder {
                color: #fff;
              }
            }
            &:active {
              background-color: rgba(123, 94, 234, 0.7);
              color: #fff;
            }
            &::placeholder {
              text-transform: uppercase;
              color: #9fa4a6;
              font-size: 11px;
            }
          }
          .OTP_input_field {
            width: 100% !important;
            overflow-y: hidden;
          }
          input:-webkit-autofill {
            background-color: rgba(123, 94, 234, 0.7) !important;
          }
        }
        .form_group_OTP {
          width: 100%;
          display: grid;
          grid-template-columns: 7fr 3fr;
          @include breakpoints(mdlgMobile) {
            grid-template-columns: 3fr 2fr;
          }
          gap: 5px;
          .OTP_resend_btn {
            background-color: #7b5eea;
            // padding: 8px 12px;
            height: 36px;
            align-self: flex-end;
            white-space: nowrap;
            border-radius: 3px;
            border: none;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            @include breakpoints(mdlgMobile) {
              font-size: 12px;
            }
            &:hover {
              background-color: #7b5eeaa6;
              box-shadow: $box-shadow;
            }
          }
        }
        .submit_btn {
          background-color: #00a5ec;
          padding: 10px 12px;
          width: 100%;
          border-radius: 3px;
          border: none;
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
          color: #fff;
          cursor: pointer;
          transition: all 0.3s ease-in-out;
          margin-top: 10px !important;
          &:hover {
            background-color: #0183bb;
            box-shadow: $box-shadow;
          }
        }
        .go_to_login {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 15px;
          text-align: center;
          p {
            color: #000;
            font-size: 14px;
            font-weight: 600;
            padding: 0px 5px;
            a {
              color: #7a5eeaf8 !important;
            }
          }
          .log_page_nav_link {
            &:hover {
              border-bottom: 2px solid #7b5eea;
              color: #7b5eea;
            }
          }
        }
        .login_nav_break_point {
          color: #7a5eeaf8 !important;
        }
      }
    }
    .bar {
      text-align: left;
      line-height: 30px;
      height: 30px;
      width: 100%;
      background-color: #c5c9ca;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .bar span {
      position: relative;
      background-color: #fdbd37;
      margin-left: 2em;
      display: inline-block;
      height: 0.8em;
      width: 0.8em;
      border-radius: 50%;
    }
    .bar span:before {
      left: -1.05em;
      background-color: #f16063;
      position: absolute;
      top: 0;
      content: "";
      display: inline-block;
      height: 0.8em;
      width: 0.8em;
      border-radius: 50%;
    }
    .bar span:after {
      left: 1em;
      background-color: #40c246ff;
      position: absolute;
      top: 0;
      content: "";
      display: inline-block;
      height: 0.8em;
      width: 0.8em;
      border-radius: 50%;
    }
    .register-body {
      .text-success {
        text-align: left;
        color: #26A59A !important;
        h4 {
          margin-bottom: 0.5rem;
          font-family: inherit;
          font-weight: 500;
          line-height: 1.2;
          color: inherit;
          @include breakpoints(mdlgMobile) {
            font-size: 15px;
          }
        }
      }

      min-height: 1px;
      padding: 1.25rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      text-align: left;
      border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      background-color: rgb(255, 255, 255);
      @include breakpoints(tablet) {
        flex-direction: column;
      }
      .half-width {
        width: 50%;
        @include breakpoints(tablet) {
          width: 100%;
        }
      }
      .left-content {
        height: 100%;
        background: #2a2e4bff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-bottom-left-radius: 5px;
        img {
          width: 70%;
          height: 70%;
          -webkit-animation: bounce 1.8s ease-in-out 0s infinite alternate;
          animation: bounce 1.8s ease-in-out 0s infinite alternate;
          @include breakpoints(tablet) {
            width: 400px;
            max-width: 100%;
          }
        }
        & h2 {
          color: #fff;
          padding: 20px 10px;
          font-size: 30px;
          text-align: center;
          font-weight: 900 !important;
        }
        & p {
          /* padding: 20px; */
          font-size: 15px;
          font-weight: 300 !important;
          color: #ccc;
          text-align: center;
        }
        @include breakpoints(tablet) {
          display: none;
        }
      }
      .right-content {
        padding: 20px;
        background: rgb(1 21 54);
      }
      .input-group {
        position: relative;
        // display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
      }
      .row {
        // display: ;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: 2.5px;
        margin-left: 2.5px;
        width: 100%;

        @include breakpoints(mdlgMobile) {
          display: flex;
          flex-direction: column;
        }
        @include breakpoints(tablet) {
          // display: flex;
          // flex-direction: column;
        }
        @include breakpoints(portraitTablet) {
          display: flex;
          flex-direction: row;
        }
        .col-md-6 {
          // flex: 0 0 50%;

          position: relative;
          width: 100%;
          padding: 10px;
          padding-right: 7.5px;
          padding-left: 7.5px;
          level {
            width: 100%;
            @include breakpoints(mdlgMobile) {
              size: 15px;
              box-sizing: none;
            }
          }
          .form-control {
            display: block;
            width: 100%;
            height: calc(2.25rem + 2px);
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            box-shadow: inset 0 0 0 transparent;
            transition: border-color 0.15s ease-in-out,
              box-shadow 0.15s ease-in-out;

            // @include breakpoints(mdlgMobile) {
            //   width: 200px;
            // }
          }
        }
      }
      .already-member {
        text-align: right;
        margin: width 5px;
        justify-content: end;
        @include breakpoints(mdlgMobile) {
          size: 12px;
          text-align: center;
          // box-sizing: none;
        }
      }
    }
  }
}
